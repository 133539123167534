import cx from 'clsx'
import Button from '@/components/common/button'

const TitleBlock = ({ title, button }) => {
  return (
    <div className="container">
      <div
        className={cx(
          'row align-items-center justify-content-center text-center justify-content-lg-start text-lg-left'
        )}
      >
        <div
          className={cx('col-12 col-lg-6', {
            ['mb-5 mb-lg-0']: button
          })}
        >
          <h2 className="mb-0">{title}</h2>
        </div>
        {button?.title && (
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
            <Button href={button.url}>{button.title}</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TitleBlock
