import styles from './link-columns.module.scss'
import Button from '@/components/common/button'
import LinkList from '@/components/common/link-list'
import Icon from '@/components/common/icon'

const LinkColumns = ({ columns }) => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className="row">
          {columns.map((column, i) => (
            <div className="col-12 col-md-6 col-lg-4 mb-10" key={i}>
              {column.title && (
                <div className={styles.title}>
                  {column.icon && (
                    <div className="mr-3">
                      <Icon icon={column.icon} size={column.iconSize} />
                    </div>
                  )}
                  <h3 className="mb-0">{column.title}</h3>
                </div>
              )}
              {column.items && <LinkList items={column.items} arrow={true} />}
              {column?.button?.title && (
                <div className="mt-4">
                  <Button href={column.button.url}>
                    {column.button.title}
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LinkColumns
