import { useState, useEffect, useCallback } from 'react'
import useResize from '@/hooks/useResize'
import useEmblaCarousel from 'embla-carousel-react'

const widths = {
  scrollSlidesXl: 1408,
  scrollSlidesLg: 1216,
  scrollSlidesMd: 1024,
  scrollSlidesSm: 768,
  scrollSlidesXs: 500,
  scrollSlides: 0
}

const getSlidesToScroll = props => {
  const matched = Object.keys(widths).find(label => {
    return props[label] && window.innerWidth > widths[label]
  })
  return matched ? props[matched] : 1
}

const useEmbla = props => {
  const width = useResize()

  let options = {
    skipSnaps: false
  }

  // Global vars
  const [viewportRef, embla] = useEmblaCarousel(options)
  const [slidesToScroll, setSlidesToScroll] = useState(0)
  const [selected, setSelected] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  // set the num of slides whenever the width changes
  useEffect(() => {
    setSlidesToScroll(getSlidesToScroll(props))
  }, [width])

  // reinitialize whenever the number of slides changes
  useEffect(() => {
    if (!embla) return
    embla.reInit({ ...options, slidesToScroll })
    embla.scrollTo(0)
    setScrollSnaps(embla.scrollSnapList())
  }, [embla, slidesToScroll])

  // on change, set the index
  const onSelect = useCallback(() => {
    if (!embla) return
    setSelected(embla.selectedScrollSnap())
  }, [embla, setSelected])

  // init, create onSelect callbcck
  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return [viewportRef, embla, slidesToScroll, selected, scrollSnaps]
}

export default useEmbla
