import cx from 'clsx'
import styles from './alternating-content.module.scss'
import Picture from '@/components/common/picture'

const AlternatingContent = props => {
  return (
    <div className={styles.root}>
      <div className="container">
        <div className="row">
          <div
            className={cx('col-12 col-lg-6', {
              ['order-md-2']: props.flip
            })}
          >
            <div className={cx(styles.image, 'responsive-block')}>
              <Picture image={props.image} />
            </div>
          </div>
          <div
            className={cx('col-12 col-lg-6', {
              ['order-md-1']: props.flip
            })}
          >
            <div
              className={cx(styles.content, 'pt-5')}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlternatingContent
