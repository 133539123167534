import cx from 'clsx'
import asPrice from '@/lib/price'
import styles from './price.module.scss'

const Price = ({ variants, large, selected }) => {
  if (selected !== undefined) {
    const price = variants[selected].price
    const comparePrice = variants[selected].compareAtPrice

    return (
      <p
        className={cx(styles.price, 'mb-0', {
          [styles.large]: large,
          ['h3']: large,
          ['p-lg']: !large
        })}
      >
        {comparePrice !== null && Number(comparePrice) > Number(price) ? (
          <>
            <ins>
              <span data-ge-price>{asPrice(price)}</span>
            </ins>
            <del>
              <span data-ge-price>{asPrice(comparePrice)}</span>
            </del>
          </>
        ) : (
          <span data-ge-price>{asPrice(price)}</span>
        )}
      </p>
    )
  }

  // Price
  const prices = variants.map(o => o.price)
  const comparePrices = variants.map(o => o.compareAtPrice || o.price)

  // Current prices
  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)

  // Old prices
  const minComparePrice = Math.min(...comparePrices)

  return (
    <p
      className={cx(styles.price, 'mb-0', {
        [styles.large]: large,
        ['h3']: large,
        ['p-lg']: !large
      })}
    >
      {minPrice !== maxPrice ? (
        <>
          <span className="mr-1 p-sm text-uppercase">From:</span>
          <span data-ge-price style={{color: 'red'}}>{asPrice(minPrice)}</span>
        </>
      ) : minPrice < minComparePrice ? (
        <>
          <ins>
            <span data-ge-price>{asPrice(minPrice)}</span>
          </ins>
          <del>
            <span data-ge-price>{asPrice(minComparePrice)}</span>
          </del>
        </>
      ) : (
        <span data-ge-price>{asPrice(minPrice)}</span>
      )}
    </p>
  )
}

export default Price
