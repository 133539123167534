import cx from 'clsx'
import styles from './tabs.module'
import { useState } from 'react'
import { TabTitle } from './includes/tab-title'

const Tabs = ({
  items,
  vertical = false,
  large = false,
  tab = 0,
  ...globalProps
}) => {
  const [selected, setSelected] = useState(tab)
  const selectedItem = items[selected || 0]
  const { component: Component, props = {} } = selectedItem

  const newProps = {
    slug: selectedItem.title.replace(/\s+/g, '-').toLowerCase(),
    ...globalProps,
    ...props
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.vertical]: vertical,
        [styles.large]: large
      })}
    >
      <ul className={styles.navigation}>
        {items.map((item, i) => (
          <TabTitle
            key={i}
            title={item.title}
            onClick={() => setSelected(i)}
            selected={i == selected}
          />
        ))}
      </ul>
      <div className={styles.content}>
        <Component {...newProps} />
      </div>
    </div>
  )
}

export default Tabs
