import Icon from '@/components/common/icon'

export const EmblaArrows = ({ embla }) => {
  if (!embla) return null

  return (
    <div className="embla__arrows">
      <PrevButton onClick={embla.scrollPrev} enabled={embla.canScrollPrev()} />
      <NextButton onClick={embla.scrollNext} enabled={embla.canScrollNext()} />
    </div>
  )
}

const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon icon="ArrowLeft" size="2x" />
  </button>
)

const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon icon="ArrowRight" size="2x" />
  </button>
)
