import Icon from '@/components/common/icon'
import Link from '@/components/common/link'
import styles from './product-item.module.scss'
import Button from '@/components/common/button/button'
import Color from '@/components/common/color'
import Labels from '@/components/product/labels'
import Price from '@/components/product/price'
import availableOptions from '@/lib/available-options'
import { colorToUrl } from '@/lib/query-strings'

const ProductItem = ({ product }) => {
  // Product image
  let image = product.images.length ? product.images[0].originalSrc : ''

  if (image.includes('cdn.shopify.com')) {
    image = image.split('.jpg').join('_x750.jpg')
  }

  const imageAlt = product.images.length ? product.images[0].altText : ''

  // Get available colors
  let availableColors = availableOptions(product?.variants, 'Color')

  // If we have availableColors as a property on the product object (block product injection does)
  if(product.availableColors) {
    if(product.availableColors.length > 0) {
      availableColors = product.availableColors
    }
  }


  // Functionality to split out matched colour images

  let matchedColor = []
  let specificVariants = product.variants.filter(variant => {
    let color = variant.selectedOptions.find(o => o.name == 'Color').value


    if(product.title.includes(color)) matchedColor.push(variant)
    if (variant.ref == product.ref) return variant
  })

  if(matchedColor.length > 0) {
    let variantImages = matchedColor.map(v => v.image)

    const images = product.images.filter(o => variantImages.includes(o.id))

    image = images[0]?.originalSrc
  }

  // Safe color string
  const urlColor = product.color ? '/?color=' + colorToUrl(product.color) : ''

  return (
    <Link
      className={styles.root}
      href={`/products/${product.handle}${urlColor}`}
    >
      <div className={styles.wrap}>
        <div className={styles.image}>
          <div className={styles.button}>
            <Button>View Product</Button>
          </div>
          {availableColors && (
            <div className={styles.colors}>
              <p className="mb-1 color-white text-uppercase text-bold p-sm">
                Colour Options
              </p>
              <ul>
                {availableColors.map((color, i) => (
                  <li key={i}>
                    <Color color={color} small={true} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Labels product={product} labels={product.labels} />
          {image && (
            <div className={styles.imageInner}>
              <img src={image} alt={imageAlt} />
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.contentInner}>
            <p className={styles.title}>{product.title}</p>
            <div className={styles.price}>
              <Price variants={product.variants} />
            </div>
          </div>
          <div className={styles.icon}>
            <Icon icon="ArrowRight" size="lg" />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ProductItem
