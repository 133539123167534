import cx from 'clsx'
import styles from '../tabs.module.scss'

export const TabTitle = ({ title, selected, onClick }) => (
  <li
    className={cx(styles.tab, {
      [styles.active]: selected
    })}
  >
    <button onClick={onClick} className="px-6 py-4 text-uppercase text-bold">
      {title}
    </button>
  </li>
)
