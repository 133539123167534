import styles from './banner-slider.module.scss'
import BannerItem from './includes/banner-item'
import EmblaCarousel from '@/components/common/embla-carousel'

const BannerSlider = props => {
  const slides = props.slides.map((slide, i) => (
    <BannerItem key={i} {...slide} />
  ))

  return (
    <div className={styles.root}>
      <EmblaCarousel
        slides={slides}
        dots={slides.length > 1 ? true : false}
        arrows={false}
        autoplay={true}
      />
    </div>
  )
}

export default BannerSlider
