import cx from 'clsx'
import Button from '@/components/common/button'
import BannerOverlay from '@/components/common/banner-overlay'
import BannerMedia from '@/components/common/banner-media'
import styles from '../banner-slider.module.scss'

const BannerItem = ({
  content,
  contentImages,
  contentWhite,
  button,
  image,
  imageMob
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.contentWrap}>
        <div className="container px-0 h-100">
          <div className="row no-gutters h-100">
            {contentImages && (
              <div className="col-12 col-sm-6 order-sm-2">
                <div className={styles.contentImages}>
                  {contentImages.map((item, i) => (
                    <div className={styles.contentImage} key={i}>
                      {item?.image && (
                        <img
                          src={item?.image?.src}
                          alt={item?.image?.alt}
                          className="img-fluid"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="col-12 col-sm-6 order-sm-1">
              <div
                className={cx(styles.content, {
                  [styles.invert]: contentWhite
                })}
              >
                {content && (
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                )}
                {button?.title && (
                  <div className="mt-3">
                    <Button
                      lightHover={button.white ? true : false}
                      href={button.url}
                    >
                      {button.title}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerOverlay invert={contentWhite ? true : false} />
      <BannerMedia media={image} mediaMob={imageMob} />
    </div>
  )
}

export default BannerItem
