import cx from 'clsx'
import { EmblaThumbs } from './includes/embla-thumbs'
import { EmblaArrows } from './includes/embla-arrows'
import { EmblaDots } from './includes/embla-dots'
import useEmbla from './includes/useEmbla'
import useAutoplay from './includes/useAutoplay'

// @ref: https://codesandbox.io/examples/package/embla-carousel-react
const EmblaCarousel = ({
  thumbs,
  dots,
  arrows,
  slides,
  autoplay,
  showOverflow,
  ...props
}) => {
  const [viewportRef, embla, numSlides, selected, scrollSnaps] = useEmbla(props)

  const pause = useAutoplay(embla, autoplay)

  return (
    <div
      className={cx('embla-wrap', {
        'embla-wrap--thumb': thumbs
      })}
    >
      <div className="embla embla--main">
        <div
          className={cx('embla__viewport', {
            'show-overflow': showOverflow
          })}
          ref={viewportRef}
        >
          <div className="embla__container">
            {slides.map((slide, index) => (
              <div
                key={index}
                className="embla__slide"
                style={{ minWidth: 100 / numSlides + '%' }}
              >
                {slide}
              </div>
            ))}
          </div>
        </div>
        {arrows && <EmblaArrows embla={embla} />}
      </div>
      {thumbs && (
        <EmblaThumbs
          embla={embla}
          slides={slides}
          selected={selected}
          scrollSnaps={scrollSnaps}
          pause={pause}
        />
      )}
      {dots && (
        <EmblaDots
          embla={embla}
          selected={selected}
          scrollSnaps={scrollSnaps}
        />
      )}
    </div>
  )
}

export default EmblaCarousel
