import styles from './tabs-accordions.module.scss'
import Tabs from '@/components/common/tabs'
import Accordion from '@/components/common/accordion'

const TabsAccordions = props => {
  const items = props.items.map(item => ({
    ...item,
    component: Accordion
  }))

  return (
    <div className={styles.root}>
      <div className="container">
        <Tabs items={items} />
      </div>
    </div>
  )
}

export default TabsAccordions
