import cx from 'clsx'
import styles from '../accordion.module.scss'
import Icon from '@/components/common/icon'
import { useRef, useEffect, useState } from 'react'

export const AccordionItem = ({ item, active, arrows }) => {
  const contentEl = useRef()
  const [height, setHeight] = useState(null)

  const preventLink = e => {
    e.preventDefault()
  }

  useEffect(() => {
    if (contentEl.current) {
      setHeight(contentEl.current.scrollHeight)
    }
  }, [active])

  return (
    <>
      <a href={item.url} onClick={item.content ? preventLink : undefined}>
        <span>{item.title}</span>
        {arrows && (
          <span className={styles.arrow}>
            <Icon icon="ArrowRight" size="lg" />
          </span>
        )}
      </a>
      {item.content && (
        <div
          ref={contentEl}
          className={styles.inner}
          style={active ? { height: height } : { height: '0px' }}
        >
          {!item.contentNotString ? (
            <div
              className="py-4"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          ) : (
            item.content()
          )}
        </div>
      )}
    </>
  )
}
