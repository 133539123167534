import cx from 'clsx'
import styles from './content-block.module.scss'

const ContentBlock = ({
  reduceWidth,
  className,
  invert,
  align,
  justify,
  content
}) => {
  const alignText =
    align == 'center' ? 'center' : align == 'right' ? 'right' : 'left'

  const justifyContent =
    justify == 'center' ? 'center' : justify == 'right' ? 'end' : 'start'

  return (
    <div
      className={cx(styles.root, className, {
        [styles.invert]: invert
      })}
    >
      {reduceWidth ? (
        <div className="container">
          <div
            className={cx(
              'row',
              'justify-content-' + justifyContent,
              'text-' + alignText
            )}
          >
            <div className="col-12 col-md-10 col-lg-8">
              <div
                className={cx(styles.inner, 'text-' + align)}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div
            className={cx(styles.inner, 'text-' + align)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}
    </div>
  )
}

export default ContentBlock
