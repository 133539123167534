import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'

const useResize = () => {
  const [width, setWidth] = useState(0)

  const listener = debounce(() => {
    setWidth(window.innerWidth)
  }, 500)

  useEffect(() => {
    window.addEventListener('resize', listener)
    listener()
    return () => window.removeEventListener('resize', listener)
  }, [])

  return typeof window === 'undefined' ? 0 : window.innerWidth
}

export default useResize
