import Picture from '@/components/common/picture'
import Video from '@/components/common/video'
import styles from './banner-media.module.scss'

export const BannerMedia = ({ media, mediaMob }) => {
  const desktopType = media?.type ?? ''
  const mobileType = mediaMob?.type ?? ''

  const display =
    desktopType.includes('video') && mobileType.includes('video')
      ? 'video'
      : desktopType.includes('video') && !mobileType.includes('video')
      ? 'video-desktop'
      : !desktopType.includes('video') && mobileType.includes('video')
      ? 'video-mobile'
      : 'image'

  return (
    <>
      {display == 'video' ? (
        <>
          <div className="d-none d-sm-block">
            <Video video={media} background={true} />
          </div>
          <div className="d-block d-sm-none">
            <Video video={mediaMob} background={true} />
          </div>
        </>
      ) : display == 'video-desktop' ? (
        <>
          <div className="d-none d-sm-block">
            <Video video={media} background={true} />
          </div>
          <div className="d-block d-sm-none">
            <div className={styles.bannerImage}>
              <img src={mediaMob?.src} alt={mediaMob?.alt} />
            </div>
          </div>
        </>
      ) : display == 'video-mobile' ? (
        <>
          <div className="d-none d-sm-block">
            <div className={styles.bannerImage}>
              <img src={media?.src} alt={media?.alt} />
            </div>
          </div>
          <div className="d-block d-sm-none">
            <Video video={mediaMob} background={true} />
          </div>
        </>
      ) : (
        <Picture image={media} imageMob={mediaMob} />
      )}
    </>
  )
}

export default BannerMedia
