import styles from './product-slider.module.scss'
import ProductItem from '@/components/product/product-item'
import EmblaCarousel from '@/components/common/embla-carousel'

const ProductSlider = props => {
  const slides = props.slides.map(product => (
    <ProductItem key={product.id} product={product} />
  ))

  return (
    <div className={styles.root}>
      <div className="container">
        <EmblaCarousel
          slides={slides}
          dots={false}
          arrows={true}
          showOverflow={true}
          scrollSlides={1}
          scrollSlidesSm={2}
          scrollSlidesLg={3}
        />
      </div>
    </div>
  )
}

export default ProductSlider
