import cx from 'clsx'
import styles from './accordion.module.scss'
import { AccordionItem } from './includes/accordion-item'
import { useState } from 'react'

const Accordion = props => {
  const hasSlug = props.slug ? props.slug + '-' : ''
  const [clicked, setClicked] = useState(null)

  const handleToggle = index => {
    if (clicked === index) {
      return setClicked(null)
    }
    setClicked(index)
  }

  return (
    <ul className={cx(styles.list, props.className)}>
      {props.items.map((item, index) => (
        <li
          className={cx(styles.item, {
            [styles.active]: clicked === hasSlug + index,
            [styles.titleBold]: props.titleBold
          })}
          onClick={() => handleToggle(hasSlug + index)}
          key={index}
        >
          <AccordionItem
            item={item}
            active={clicked === hasSlug + index}
            arrows={props.arrows ?? true}
          />
        </li>
      ))}
    </ul>
  )
}

export default Accordion
