import { useState, useEffect } from 'react'
import useInterval from '@/hooks/useInterval'

const AUTOPLAY_INTERVAL = 6000
const AUTOPLAY_PAUSE_INTERVAL = 10000

const useAutoplay = (embla, autoplay) => {
  const [autoplayInterval, setAutoplayInterval] = useState(
    autoplay ? AUTOPLAY_INTERVAL : null
  )

  // pause the carousel. restart after X seconds
  const pause = () => {
    setAutoplayInterval(null)
    if (!autoplay) return

    setTimeout(() => {
      setAutoplayInterval(AUTOPLAY_INTERVAL)
    }, AUTOPLAY_PAUSE_INTERVAL)
  }

  // autoplay
  useInterval(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, autoplayInterval)

  // init
  useEffect(() => {
    if (!embla) return
    embla.on('pointerDown', pause)
  }, [embla])

  return pause
}

export default useAutoplay
