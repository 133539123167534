import cx from 'clsx'
import styles from './video.module.scss'

export default function Video({ video, background }) {
  if (!video) {
    return null
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.background]: background
      })}
    >
      <video
        src={video.src}
        autoPlay={background ? true : false}
        loop={background ? true : false}
        muted={background ? true : false}
        controls={background ? false : true}
      ></video>
    </div>
  )
}
