import cx from 'clsx'
import { useEffect } from 'react'

const Storystream = ({ appId, type, className }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = `https://apps.storystream.ai/app/js/${appId}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <div id="stry-wrapper" className={cx(type, className)}></div>
}

export default Storystream
