export const EmblaDots = ({ embla, selected, scrollSnaps }) => {
  if (!embla || !scrollSnaps.length) return null

  return (
    <div className="embla__dots">
      {scrollSnaps.map((_, index) => (
        <DotButton
          key={index}
          selected={index === selected}
          onClick={() => embla.scrollTo(index)}
        />
      ))}
    </div>
  )
}

const DotButton = ({ selected, onClick }) => (
  <button
    className={`embla__dot ${selected ? 'is-selected' : ''}`}
    type="button"
    onClick={onClick}
  />
)
