import cx from 'clsx'
import Button from '@/components/common/button'
import Picture from '@/components/common/picture'
import Video from '@/components/common/video'
import BannerOverlay from '@/components/common/banner-overlay'
import styles from './banner-static.module.scss'

const BannerStatic = ({
  content,
  contentRight,
  contentWide,
  contentWhite,
  button,
  video,
  image,
  imageMob,
  visible
}) => {
  return (
    <div className={styles.root}>
      <div
        className={cx(styles.content, {
          [styles.invert]: contentWhite
        })}
      >
        <div className="container px-0">
          <div
            className={cx('row no-gutters', {
              ['text-sm-right justify-content-sm-end']: contentRight
            })}
          >
            <div
              className={cx('col-12', {
                ['col-sm-9 col-lg-7 col-xl-6']: !contentWide,
                ['col-sm-10 col-lg-8 col-xl-7']: contentWide
              })}
            >
              {content && visible && (
                <div className={styles.contentDesc} dangerouslySetInnerHTML={{ __html: content }}></div>
              )}

              {content && !visible && (
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
              )}

              {button?.title && (
                <div className="mt-3">
                  <Button
                    lightHover={button.white ? true : false}
                    href={button.url}
                  >
                    {button.title}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BannerOverlay invert={contentWhite ? true : false} />
      {video ? (
        <Video video={video} background={true} />
      ) : (
        <Picture image={image} imageMob={imageMob} />
      )}
    </div>
  )
}

export default BannerStatic
