import cx from 'clsx'
import { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

export const EmblaThumbs = ({
  embla,
  slides,
  selected,
  scrollSnaps,
  pause
}) => {
  // create embla ref
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    selectedClass: '',
    dragFree: true
  })

  // calculate snaps per view
  const [snapsPerView, setSnapsPerView] = useState(
    Math.ceil(slides.length / scrollSnaps.length)
  )

  // adjust snaps per view if scrollSnaps changes
  useEffect(() => {
    setSnapsPerView(Math.ceil(slides.length / scrollSnaps.length))
  }, [scrollSnaps, slides])

  // when selected changes, move the thumbnail
  useEffect(() => {
    if (!emblaThumbs) return
    emblaThumbs.scrollTo(selected * snapsPerView)
  }, [selected, snapsPerView])

  // on click, scroll the main embla view
  const onThumbClick = useCallback(
    index => {
      if (!embla || !emblaThumbs) return
      if (emblaThumbs.clickAllowed())
        embla.scrollTo(Math.floor(index / snapsPerView))
    },
    [embla, emblaThumbs, snapsPerView]
  )

  // init
  useEffect(() => {
    if (!emblaThumbs) return
    emblaThumbs.on('pointerDown', pause)
  }, [emblaThumbs])

  if (!embla) return null

  return (
    <div className="embla embla--thumb">
      <div className="embla__viewport" ref={thumbViewportRef}>
        <div className="embla__container embla__container--thumb">
          {slides.map((slide, index) => (
            <Thumb
              key={index}
              onClick={() => onThumbClick(index)}
              selected={Math.floor(index / snapsPerView) === selected}
              slide={slide}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Thumb = ({ selected, onClick, slide }) => (
  <div
    onClick={onClick}
    className={cx('embla__slide embla__slide--thumb', {
      'is-selected': selected
    })}
  >
    {slide}
  </div>
)
