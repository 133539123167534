import cx from 'clsx'
import BannerItem from './includes/banner-item'
import styles from './banner-grid.module.scss'

const BannerGrid = props => {
  return (
    <div className={cx('container-fluid', styles.root)}>
      {props.columns && (
        <div className="row">
          {props.columns.map((item, i) => (
            <div key={i} className="col-12 col-sm-6 mb-4">
              <BannerItem {...item} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default BannerGrid
