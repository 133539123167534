import cx from 'clsx'
import Button from '@/components/common/button'
import Picture from '@/components/common/picture'
import Video from '@/components/common/video'
import BannerOverlay from '@/components/common/banner-overlay'
import styles from '../banner-grid.module.scss'

const BannerItem = ({
  content,
  contentWhite,
  button,
  video,
  image,
  imageMob
}) => {
  return (
    <div className={styles.banner}>
      <div
        className={cx(styles.content, {
          [styles.invert]: contentWhite
        })}
      >
        <div className={styles.contentInner}>
          {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
          {button?.title && (
            <div className="mt-3">
              <Button
                lightHover={button.white ? true : false}
                href={button.url}
              >
                {button.title}
              </Button>
            </div>
          )}
        </div>
      </div>
      <BannerOverlay invert={contentWhite ? true : false} />
      {video ? (
        <Video video={video} background={true} />
      ) : (
        <Picture image={image} imageMob={imageMob} />
      )}
    </div>
  )
}

export default BannerItem
